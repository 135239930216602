@use "sass:color";

// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$logo-background-color: rgb(43, 62, 60);
$header-background-color: #7d9d9c;
$logo-guitar-color: #7d9d9c;

// color.adjust($logo-background-color, $lightness: 40%);
$schedule-name-band-color: #7f7;
// $schedule-name-acoustic-color: #ddf;
$schedule-name-acoustic-color: color.adjust($header-background-color, $lightness: 40%);

$spacing-unit:     30px;

$header-text-color: #fdfdfd;
$text-color:        white;
$background-color:  color.adjust($logo-background-color, $lightness: -10%);
$odd-row-color:     #444;
$brand-color:       #70c0ff;

$grey-color:       #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

// Width of the content area
$content-width:    900px;

$on-palm:          600px;
$on-laptop:        900px;
