@charset "utf-8";

@use "_base";
@use "_layout";
@use "_syntax-highlighting";
@use "_vars" as *;


h1 {
  background-color: $header-background-color;
  padding-left: 1em;
}

h2 {
  background-color: $logo-background-color;
  padding-left: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

p {
  padding-left: 2em;
}

li p {
  padding-left: 0;
}
