@use "_vars" as *;
@use "_clearfix" as cfix;
@use "_mquery" as mq;

/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;
    background-color: $background-color;
    background-image: url(/images/mixer_horiz.jpg);
    background-repeat: no-repeat;
    background-position: center;

    // Positioning context for the mobile navigation icon
    position: relative;
    vertical-align: bottom;
}

.site-title {
    margin-bottom: 0;
    float: left;
}

.site-nav {
    float: right;
    line-height: 56px;

    padding: 0 1em 0 1em;
    background: $logo-background-color;
    border: 1px solid black;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $header-text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include mq.media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
            color: $text-color;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: calc(0px - ($spacing-unit / 2));
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include mq.media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include mq.media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include mq.media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include mq.media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include mq.media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include mq.media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

table.bandpics {
  td.bandmember {
    padding: 1em;
    background: $grey-color-dark;
    vertical-align: top;
  }
  p {
    padding-left: 0;
    padding-top: 1em;
  }
}
table.bandpics-2 {
  margin-left: auto;
  margin-right: auto;
  width: 66%;
  td {
    width: 50%;
  }
}

div#schedule-list {
  div.row {
    div.column {
      float: left;
      padding: 10px;
    }

    .left {
      width: 7em;
    }
    .left + .right {
      width: calc(100% - 7em - 3em);
    }
    .middle {
      width: calc(100% - 7em - 170px - 3em);
    }
    .midde + .right {
      width: 150px;
      text-align: right;
    }

    img.schedule {
      width: 150px;
    }

    @include mq.media-query($on-palm) {
      .middle {
        width: calc(100% - 10em);
      }
      .midde + .right {
        display: none;
        visibility: hidden;
      }

      img.schedule {
        display: none;
        visibility: hidden;
      }
    }
  }

  /* Clear floats after the columns */
  div.row:after {
    content: "";
    display: table;
    clear: both;
  }
}

img.schedule-logo {
  float: right;
  margin-top: 4em;
  margin-left: 2em;
}

img.mainLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

ul.schedule {
  list-style: none;
  li {
    margin-top: 2em;
    margin-bottom: 1em;
  }
}

div.schedule-date {
  width: 6em;
  margin-right: 1em;
  div.month {
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    background-color: grey;
    text-align: center;
    padding-top: 0.4em;
    padding-bottom: 0.1em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  div.day-name {
    font-size: 14px;
    color: grey;
    background-color: white;
    text-align: center;
    padding-top: 0.4em;
  }
  div.day {
    font-size: 24px;
    color: grey;
    background-color: white;
    text-align: center;
    padding-bottom: 0.2em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

div.schedule-description {
  padding-top: 0.4em;
}

/*
div.schedule-text {
  flex: 1;
  margin-top: -0.2em;
}
*/

div.schedule-name {
  font-weight: bolder;
  font-size: 1.3em;
  span.band {
    color: $schedule-name-band-color;
  }
  span.acoustic {
    color: $schedule-name-acoustic-color;
  }
}

h2#past-gigs + table {
  width: 95%;
  margin-left: 1.5em;
  border-collapse: collapse;
  th {
    text-align: left;
    border-bottom: 1px solid gray;
  }
  td {
    border-bottom: 1px solid gray;
    padding-right: 1em;
  }
}

/* song list */

table.slinfo {
    width: 100%;
    padding-bottom: 1em;
    td.showhide {
        text-align:right;
    }
}

table#songlist {
    width: 100%;
    th,td {
        padding-left: 1em;
        padding-right: 1em;
        text-align: left;
    }
    tr:nth-child(odd) {
        background: $odd-row-color;
    }
    td.rownum {
        text-align: right;
    }
    th {
        background: $text-color;
        color: $background-color;
    }
    th.sorter {
        cursor: pointer;
    }
}

/* youtube videos */
div.video-iframe {
    text-align: center;
    iframe {
        margin: auto;
    }
}

/* other videos */
div.video {
    text-align: center;
}


div.center {
    text-align: center;
}

/**
 * Modal for images
 */
 /* Style the Image Used to Trigger the Modal */
.myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
    margin: auto;
    display: block;
    max-width: 90%;
    height: auto;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    font-weight: bold;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modal-content {
        width: 100%;
    }
}
